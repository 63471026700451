import { Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AppContext from '../../../AppContext';
import TableCustom from '../../component/table/table-custom/TableCustom';
import { TYPE } from '../../utils/Constant';
import { dsNguoiXemColumns } from '../const/constants';
import { getListUser, getListUserView, setAuthoriyViewUser } from '../services/BaoCaoSCYKServices';
import InputSearch from './../../component/InputSearch';
import { localStorageItem } from '../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from '../../auth/core/_consts';

type Props = {
  handleClose: () => void
  suCoId: string
}

const SelectViewPersonModal = ({ handleClose, suCoId }: Props) => {
  const handleSubmit = async (values: any) => {
    try {
      await setAuthoriyViewUser(suCoId, values);
      handleClose()
      toast.success("Phân quyền thành công");

    } catch (error) {
      toast.error(String(error));
    }
  }

  const { setPageLoading } = useContext(AppContext);
  const [configTable, setConfigTable] = useState<any>({});
  const [dsUser, setDsUser] = useState<any>([])
  const [dsUserChecked, setDsUserChecked] = useState<any>([])
  const [searchObj, setSearchObj] = useState<any>({ PageNumber: 1, PageSize: 10 })
  const [dataSelected, setDataSelected] = useState<any>([])

  const getDSNguoiXem = async (searchData: any) => {
    try {
      setPageLoading(true);

      const [dataResponse, dataCheckedResponse] = await Promise.all([
        getListUser(searchData),
        getListUserView(suCoId)
      ]);

      setDsUser(dataResponse?.data?.data?.data);
      setDsUserChecked(dataCheckedResponse?.data?.data);
      setConfigTable({
        totalElement: dataResponse?.data?.data?.total,
        totalPages: dataResponse?.data?.data?.totalPages,
        numberOfElements: dataResponse?.data?.data?.numberOfElements,
        pageSize: dataResponse?.data?.data?.pageSize,
        pageIndex: dataResponse?.data?.data?.pageNumber,
      });
    } catch (err) {
      toast.error(String(err));
    } finally {
      setPageLoading(false);
    }
  };



  useEffect(() => {
    getDSNguoiXem(searchObj);
  }, [])

  return (
    <Modal show={true} onHide={handleClose} centered size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Chọn người xem</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <InputSearch
          placeholder="Tìm kiếm người xem"
          handleChange={(e) => {
            setSearchObj({ ...searchObj, name: e.target.value })
          }}
          className="spaces h-32 spaces mb-4"
          value={searchObj?.keyword}
          handleSearch={() => getDSNguoiXem(searchObj)}
        />
        <TableCustom
          height={"calc(100vh - 400px)"}
          id="profile2-3"
          columns={dsNguoiXemColumns}
          data={dsUser}
          setDataChecked={(data: any) => setDataSelected(data?.map((item: any) => {
            return {
              personId: item?.personId,
              userName: item?.userName
            }
          }))}
          disableRowField={[localStorageItem.get(KEY_LOCALSTORAGE.ACCESS_TOKEN_DECODE)?.sub]}
          uniquePrefix="userName"
          dataChecked={dsUserChecked}
          checkedInit={true}
          updatePageData={getDSNguoiXem}
          buttonAdd={false}
          buttonExportExcel={false}
          notDelete={false}
          justFilter={true}
          noPagination={false}
          type={TYPE.MULTILINE}
          page={configTable?.pageIndex}
          pageSize={configTable?.pageSize}
          totalElements={configTable?.totalElement}
          totalPages={configTable?.totalPages}
          numberOfElements={configTable?.numberOfElements}
        />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button
          className="button-primary"
          onClick={() => handleSubmit(dataSelected)}
        >
          Lưu
        </Button>
        <Button
          className="button-gray"
          onClick={handleClose}
        >
          Hủy
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SelectViewPersonModal